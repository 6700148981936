<template>
    <!-- 首界面路由 -->
    <div class="interface-box">
        <div class="container">
            <!-- 欢迎 -->
            <div class="interface-welcome">
                <div class="interface-welcome-en">WELCOME, TO THE FUTURE</div>
                <div class="interface-welcome-cn">欢迎来到，吉享空间</div>
                <div class="interface-welcome-nav">
                    <router-link to="/main">
                        点击进入
                    </router-link>
                    <router-link to="/about">
                        关于我们
                    </router-link>
                </div>
            </div>
            <!-- 获得影像服务的 -->
            <div class="interface-photograph">
                <!-- 文字 -->
                <div class="interface-text">
                    <div class="interface-text-content">
                        <!-- 标题 -->
                        <div class="interface-text-title">
                            获得影像服务的便捷方式
                        </div>
                        <!-- 小列表 -->
                        <ul class="interface-text-list">
                            <li>
                                <a href="javascript:;"
                                    @mouseenter="handleHover('photographSwiper',0)">
                                    丰富的拍摄主题
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;"
                                    @mouseenter="handleHover('photographSwiper',1)">
                                    透明的拍摄价格
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;"
                                    @mouseenter="handleHover('photographSwiper',2)">
                                    高效的互动系统
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 轮播图 -->
                <div class="interface-carousel">
                    <interface-carousel
                        :pic-list="photograph_pic"
                        :swiper-options="photographOption"
                        ref="photographSwiper">
                        <template v-slot:pagination>
                            <div class="swiper-pagination photograph-pagination">
                            </div>
                        </template>
                    </interface-carousel>
                </div>
            </div>
            <!-- 自由职业者 -->
            <div class="interface-freelance">
                <!-- 轮播图 -->
                <div class="interface-carousel">
                    <interface-carousel
                        :pic-list="freelance_pic"
                        :swiper-options="freelanceOption"
                        ref="freelanceSwiper">
                        <template v-slot:pagination>
                            <div class="swiper-pagination freelance-pagination">
                            </div>
                        </template>
                    </interface-carousel>
                </div>
                <!-- 文字 -->
                <div class="interface-text">
                    <div class="interface-text-content">
                        <!-- 标题 -->
                        <div class="interface-text-title">
                            自由职业者自我实现的方式
                        </div>
                        <!-- 小列表 -->
                        <ul class="interface-text-list">
                            <li>
                                <a href="javascript:;"
                                    @mouseenter="handleHover('freelanceSwiper',0)">
                                    释放按耐的创作欲望
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;"
                                    @mouseenter="handleHover('freelanceSwiper',1)">
                                    实现你爱好的价值
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;"
                                    @mouseenter="handleHover('freelanceSwiper',2)">
                                    赋予才能无限的增长空间
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- 用户高体验度 -->
            <div class="interface-experience">
                <!-- 文字 -->
                <div class="interface-text">
                    <div class="interface-text-content">
                        <!-- 标题 -->
                        <div class="interface-text-title">
                            用户高体验度的平台
                        </div>
                        <!-- 小列表 -->
                        <ul class="interface-text-list">
                            <li>
                                <a href="javascript:;"
                                    @mouseenter="handleHover('experienceSwiper',0)">
                                    给你充足的主动选择权
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;"
                                    @mouseenter="handleHover('experienceSwiper',1)">
                                    超乎想象的价格<br>
                                    丰富的特色活动
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;"
                                    @mouseenter="handleHover('experienceSwiper',2)">
                                    一站式的共享服务
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 轮播图 -->
                <div class="interface-carousel">
                    <interface-carousel
                        :pic-list="experience_pic"
                        :swiper-options="photographOption"
                        ref="experienceSwiper">
                        <template v-slot:pagination>
                            <div class="swiper-pagination photograph-pagination">
                            </div>
                        </template>
                    </interface-carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            photographOption: {
                direction: 'vertical',
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: true
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
            },
            freelanceOption: {
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: true
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
            },
            photograph_pic: [
                "photograph1.png",
                "photograph2.png",
                "photograph3.png"
            ],
            freelance_pic: [
                "freelance1.png",
                "freelance2.png",
                "freelance3.png"
            ],
            experience_pic: [
                "experience1.png",
                "experience2.png",
                "experience3.png",
            ]
        }
    },
    components: {
        interfaceCarousel: () => import("@/components/interfaceCarousel")
    },
    methods: {
        handleHover(swiper, i){
            this.$refs[swiper].$refs.swiperRef.swiper.slideTo(i, 0)
        }
    },
}
</script>

<style>
/* 首界面盒子 */
.interface-box{
}
.interface-welcome,
.interface-photograph,
.interface-freelance,
.interface-experience{
    height: 600px;
}
/* 欢迎 */
.interface-welcome{
    padding: 50px;
    background-image: url("../assets/images/interface/bg_welcome.png");
    background-size: contain;
    background-repeat:no-repeat;
    background-position: right bottom;
}
.interface-welcome-en{
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: bold;
}
.interface-welcome-cn{
    margin-bottom: 100px;
    font-size: 30px;
}
.interface-welcome-nav>a{
    display: inline-block;
    padding: 5px 20px;
    background-color: #E6A23C;
    border-radius: 20px;
    font-size: 18px;
    color: #fff;
}
.interface-welcome-nav>a+a{
    margin-left: 20px;
}
/* 其他项 */
.interface-photograph,
.interface-freelance,
.interface-experience{
    display: flex;
}
.interface-text{
    flex: 1;
    position: relative;
}
.interface-carousel{
    flex: 2;
    overflow: hidden;
}
.interface-text-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.interface-text-title{
    margin-bottom: 30px;
    font-size: 25px;
    color: #303133;
}
.interface-text-list{
    list-style: disc;
}
.interface-text-list>li>a{
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
    color: #606266;
}
.interface-text-list>li>a:hover{
    color: #8d9afc;
}
/* 轮播图 */
.photograph-pagination{
    top: 100px !important;
}
.freelance-pagination{
    left: 330px !important;
}
</style>
